.ant-upload .ant-upload-btn {
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
	background: #fff;
}
.small-area .ant-upload.ant-upload-drag {
	height: unset !important;
}
.ant-upload.ant-upload-drag {
	height: 180px;
}
.ant-upload.ant-upload-drag .ant-upload {
	padding: 20px 0;
}
.ant-upload-list {
	display: none !important;
}
