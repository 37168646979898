/* Privacy policy, Terms and conditions */
.tc-container {
	width: 1280px;
	margin: 0 auto;
	color: #0c0442;
	text-align: justify;
	font-family: Inter-regular;
}

.tc-sub-title {
	font-weight: bold;
	color: #0c0442;
}

ol.tc-ol {
	padding-left: 75px;
}

.tc-sub-title {
	font-weight: bold;
}