.customprogress {
	position: relative;
}
.customprogress:after {
	content: '\A';
	position: absolute;
	background: #4bb7ba;
	border-radius: 10px 0px 0px 10px;
	top: 0;
	bottom: 0;
	left: 0;
}

.customprogress.gradient:after {
	background: linear-gradient(90deg, #d97706 40.85%, #059669 100.28%);
}
