.custom-autocomplete-dropdown {
	font-family: 'Inter-Regular';
	background: #ffffff;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
	border-radius: 5px;
	width: auto !important;
	min-width: auto !important;
}
.ant-select-selector {
	background-color: #fcfcfc !important;
}
.custom-autocomplete-dropdown:focus:active:hover {
	font-family: 'Inter-Regluar' !important;
	background: #ffffff !important;
}

.ant-select-selection-search input {
	font: 14px 'Inter-Medium' !important;
}

.ant-select-item-option-content {
	font-family: 'Inter-Regular' !important;
	padding-right: 40px;
	word-break: break-all;
}
.ant-select-selection-search {
	color: #0c0442;
}
