.text-ellipsis-container {
	position: relative;
	line-height: 1.2em;
	max-height: 2.4em; /* Show 2 lines of text initially */
	overflow: hidden;
}

.text-content {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2; /* Limit to 2 lines of text */
	overflow: hidden;
}

.show-full-text {
	-webkit-line-clamp: 3; /* Show 3 lines of text */
}

.show-more-button {
	position: absolute;
	bottom: 32px;
	right: 36px;
	background-color: transparent;
	border: none;
	color: blue; 
	background: white;font-size: 15px;
	cursor: pointer;
}
