@font-face {
	font-family: 'Inter-Light';
	src: url('./assets/fonts/Inter-Light.ttf');
}
@font-face {
	font-family: 'Inter-Regular';
	src: url('./assets/fonts/Inter-Regular.ttf');
}
@font-face {
	font-family: 'Inter-Medium';
	src: url('./assets/fonts/Inter-Medium.ttf');
}
@font-face {
	font-family: 'Inter-SemiBold';
	src: url('./assets/fonts/Inter-SemiBold.ttf');
}
@font-face {
	font-family: 'Inter-Bold';
	src: url('./assets/fonts/Inter-Bold.ttf');
}

@font-face {
	font-family: 'Inter-Black';
	src: url('./assets/fonts/Inter-Black.ttf');
}

@font-face {
	font-family: 'Archivo-Bold';
	src: url('./assets/fonts/Archivo-Bold.ttf');
}

@font-face {
	font-family: 'Handlee-Regular';
	src: url('./assets/fonts/Handlee-Regular.ttf');
}

body {
	margin: 0;
	font-family: 'Inter-Regular';
	line-height: 1.459;
	color: #374151;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

pre {
	font-family: 'Inter-Regular';
	white-space: pre-wrap;
}

button {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}
html {
	overflow: scroll;
	overflow-x: hidden;
}

/* Slim scroll bar whole application */
::-webkit-scrollbar {
	width: 3px;
	height: 3px;
}

::-webkit-scrollbar-track {
	background: #fff;
}

::-webkit-scrollbar-thumb {
	background: #e0e0e0;
}

.scroll-hide::-webkit-scrollbar {
	width: 0px;
	background: transparent;
}

.disabled {
	opacity: 0.1;
	pointer-events: none;
	cursor: pointer;
}

.remove-p-padding > p {
	margin-bottom: 0;
}

/* content editable div inside font tag font family not getting it from parent, so added custom class */
.content-editable-parent font {
	font-family: 'Inter-Regular';
}

.bghover:hover {
	background: #cececece !important;
}
.invert-chat-messages {
	transform: rotate(180deg);
	direction: rtl;
}

#scroller * {
	overflow-anchor: none;
}

#anchor {
	overflow-anchor: auto;
	height: 1px;
}
.relative {
	position: relative !important;
}
.absolute {
	position: absolute !important;
}
.truncate {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.styleButton,
.RichEditor-styleButton {
	padding: 0.5em;
	background-image: linear-gradient(to bottom, #fdfbfb 0%, #ebedee 100%);
	border: none;
	margin: 0.35em;
	width: 2.75em;
	font-family: 'Inter-Regular';
	font-size: 13px;
	height: 2.25em;
	border-radius: 4px;
}

.sign-input-field {
	height: 70px;
}
