/*outseta custom css*/
.o--TermsAndConditionsCheckbox--markupText p {
	font-family: 'Inter-Regular' !important;
	font-size: 16px !important;
	line-height: 145.9% !important;
	color: #374151 !important;
}

.o--TermsAndConditionsCheckbox--markupText a {
	font-family: 'Inter-Semibold' !important;
	font-size: 16px !important;
	line-height: 145.9% !important;
	color: #4bb7ba !important;
}
.o--text-center.o--SectionGroup--sectionGroup,
.o--header.o--SectionGroup--sectionGroup {
	display: none !important;
}
.o--Button--btn {
	height: 41px;
	width: 100% !important;
}
.o--Button--children {
	font-family: 'Inter-Semibold' !important;
}
.o--Widget--widget .o--Button--btn {
	padding: 6px 17px !important;
}
.o--Widget--widget .o--Input--input {
	padding: 7.5px 15px !important;
}
.o--Widget--widgetBody {
	padding: 0 !important;
}
.o--SignInWithGoogle--btnGoogleSignin {
	/* min-width: 100%;
    width: 440px!important;   */
}
.o--SignInWithGoogle--btnGoogleSignin div > div > div,
.o--SignInWithGoogle--btnGoogleSignin div > div > div:hover {
	border-color: #9ca3af !important;
}
.o--SignInWithGoogle--btnGoogleSignin div > div > div div:nth-child(2) {
	/* justify-content: center!important; */
	height: 41px;
}
.o--SignInWithGoogle--btnGoogleSignin div div div div:nth-child(2) span:nth-child(2) {
	flex-grow: inherit !important;
	font-family: 'Inter-Regular';
	font-size: 14px;
	line-height: 145.9%;
}
.o--FormGroup--formGroup .o--Label--label,
.o--SectionGroup--sectionGroup .o--Label--label {
	font-family: 'Inter-Semibold' !important;
	font-size: 16px !important;
	line-height: 145.9%;
}
.o--FormGroup--formGroup input,
.o--SectionGroup--sectionGroup input {
	height: 41px !important;
}

.o--Widget--widget .o--Checkbox--Checkbox .o--Checkbox--state label * {
	font-family: 'Inter-Regular';
	font-size: 16px !important;
	color: #374151 !important;
}

.o--Widget--widget .o--Checkbox--Checkbox .o--Checkbox--state label * a {
	color: #4bb7ba !important;
}

.o--Widget--widget .o--Checkbox--Checkbox input:checked ~ .o--Checkbox--state label:after {
	background-color: #4bb7ba !important;
	border-color: #4bb7ba !important;
}

.o--Widget--widget .o--Button--btn.o--Button--buttonStyle-solid {
	background-color: #4bb7ba !important;
	border: none !important;
}
.o--Widget--widget .o--Button--btn.o--Button--buttonStyle-solid:disabled {
	background-color: #9ca3af !important;
	border: none !important;
}
.o--Widget--widget .o--Input--fieldStyle-light-border:focus {
	border-color: #4bb7ba !important;
}
.o--Widget--widget .o--Input--borderWidth-2px {
	border-width: 1px !important;
}
.o--Widget--widget .o--Input--cornerShape-rounded {
	border-radius: 5px !important;
}
.o--Widget--widget .o--Input--fieldStyle-light-border {
	border-color: #9ca3af !important;
}

.o--Widget--widget .o--Button--btn.o--Button--buttonStyle-solid.o--Button--buttonType-cancel {
	/* background-color: #9CA3AF!important;
    border: none!important; */
	border: 1px solid #9ca3af !important;
	background: #fff !important;
}

.o--SectionGroup--sectionGroup h1 {
	color: #111827 !important;
	font-size: 2.843rem !important;
	font-family: 'Archivo-Bold' !important;
}

.o--Button--children {
	font-size: 16px !important;
	font-family: 'Inter-Semibold' !important;
}

.state-registerConfirmation h1 {
	font-size: 28.43px !important;
	font-family: 'Archivo-Bold' !important;
	color: #111827 !important;
	text-align: left !important;
}

.state-registerConfirmation div:last-child {
	padding: 0 !important;
}

.state-registerConfirmation p {
	text-align: left !important;
	margin: 1rem 0 0 0 !important;
	font-family: 'Inter-Regular' !important;
}

.state-registerConfirmation .material-icons {
	font-size: 40px !important;
	color: green !important;
	display: none !important;
}

.state-login .o--text-sm {
	font-size: 16px !important;
	color: #4bb7ba !important;
	font-family: 'Inter-Semibold' !important;
}

.state-registerConfirmation button {
	margin-top: 3rem !important;
}
