.wordbreak {
	white-space: pre-wrap;
	word-wrap: break-word;
}
.text-center {
	text-align: center;
}
.pl-30 {
	padding-left: 30px;
}

.heading1 {
	font-size: 1.775rem;
	line-height: 2rem;
	font-weight: bold;
}
.heading2 {
	font-size: 1.414rem;
	line-height: 2rem;
	font-weight: bold;
}
.heading3 {
	font-size: 1.125rem;
	line-height: 1.68rem;
	font-weight: semibold;
}
.heading4 {
	font-size: 0.875rem;
	line-height: 1.313rem;
	font-weight: medium;
}
.heading5 {
	font-size: 0.75rem;
	line-height: 1.125rem;
	font-weight: regular;
}
.body1 {
	font-size: 0.875rem;
	line-height: 1.313rem;
	font-weight: regular;
}
.body2 {
	font-size: 0.75rem;
	line-height: 1.125rem;
	font-weight: regular;
}
.btn {
	font-size: 0.75rem;
	line-height: 1.125rem;
	font-weight: semibold;
}
