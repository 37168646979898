.progress-circle {
	.ant-progress-text {
		font-size: 1.4rem;
	}
	.ant-progress-inner {
		width: 55px !important;
		height: 55px !important;
	}
	.ant-progress-circle-trail {
		stroke: #d1d5db !important;
	}
}
