/*antd tab custom css*/
.ant-tabs {
	font-family: 'Inter-Regular' !important;
	color: #686673 !important;
}

.ant-tabs-tab:hover {
	color: #4bb7ba !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: #4bb7ba !important;
	font-family: 'Inter-SemiBold' !important;
}

.ant-tabs-ink-bar {
	background: #4bb7ba !important;
}

.custom-tabs .ant-tabs-tab-btn {
	font-family: 'Inter-Semibold' !important;
	font-size: 16px;
}
.custom-tabs.ant-tabs-top > .ant-tabs-nav:before {
	display: none;
}
/* .custom-tabs .ant-tabs {
	font-family: 'Inter-Semibold' !important;
	color: #686673 !important;
} */

.ant-switch {
	height: 24px;
}
.ant-switch-checked {
	background-color: #4bb7ba;
}

.ant-switch-handle {
	top: 3px;
	left: 3px;
}

.ant-list-item-action {
	width: 100%;
}

.ant-select-selection-item {
	white-space: unset !important;
}

.ant-select-selection-item-remove .anticon svg {
	fill: white !important;
}

.ant-select-selection-item-content span {
	font-family: 'Inter-Regular';
	font-style: normal;
	font-weight: 600;
	font-size: 12px !important;
}

.ant-modal-footer .ant-btn-primary {
	background: #4bb7ba !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
	background-color: #4bb7ba;
	border-color: #4bb7ba;
}

.platform-preselect .ant-checkbox-disabled .ant-checkbox-inner {
	background-color: #4bb7ba;
	border-color: #4bb7ba;
}

.ant-checkbox-wrapper-disabled span {
	color: rgba(0, 0, 0, 0.85);
}

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner:after {
	border-color: #fff;
}

.sessionCalendar .ant-picker-panel {
	border-top: transparent !important;
}
.sessionCalendar .ant-picker-content th {
	width: 42px !important;
	font-weight: 600 !important;
	visibility: hidden;
}
.sessionCalendar .ant-picker-content th::first-letter {
	visibility: visible;
}
.sessionCalendar .ant-picker-content .ant-picker-cell-inner {
	border-radius: 5px;
	border: 1px solid #e8edf2;
	margin: 0;
	width: 42px !important;
	height: 48px !important;
	display: flex;
	justify-content: center;
	align-items: start;
	padding: 7px 0;
}
.sessionCalendar .ant-picker-content .ant-picker-calendar-date-today {
	background: #e5e7eb !important;
	border: 1px solid #d1d5db !important;
	color: #374151 !important;
	border-radius: 5px;
}
.sessionCalendar
	.ant-picker-content
	.ant-picker-cell-in-view.ant-picker-cell-today
	.ant-picker-cell-inner:before {
	border: 1px solid #e8edf2 !important;
}

.sessionCalendar
	.ant-picker-content
	.ant-picker-cell-in-view.ant-picker-cell-range-end
	.ant-picker-cell-inner,
.sessionCalendar
	.ant-picker-content
	.ant-picker-cell-in-view.ant-picker-cell-range-start
	.ant-picker-cell-inner,
.sessionCalendar
	.ant-picker-content
	.ant-picker-cell-in-view.ant-picker-cell-selected
	.ant-picker-cell-inner {
	color: unset;
	/* background: transparent !important; */
}
.sessionCalendar .ant-picker-content .ant-picker-cell {
	padding: 0px !important;
}

.sessionCalendar .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
	background-color: #4bb7ba !important;
	color: white !important;
}

.sessionSiderAbs {
	position: absolute !important;
	right: 0 !important;
}
.sessionSider .ant-layout-sider-zero-width-trigger,
.sessionSider .ant-layout-sider-zero-width-trigger-left {
	display: none !important;
}

.ant-layout-sider.sessionSider {
	z-index: 1 !important;
}

.ant-layout-sider {
	z-index: 99 !important;
}

.ant-tabs-nav-wrap {
	-ms-overflow-style: none; /* Internet Explorer 10+ */
	scrollbar-width: none; /* Firefox */
}
.ant-tabs-nav-wrap::-webkit-scrollbar {
	display: none; /* Safari and Chrome */
}
.ant-select-selection-item {
	white-space: unset !important;
}

.ant-select-clear {
	z-index: 9999;
}

@media screen and (max-width: 992px) {
	.ant-layout-sider {
		position: absolute;
		height: 100vh;
		z-index: 999 !important;
	}
}

@media screen and (max-width: 480px) {
	.page-footer button.lg {
		padding: 8px 8px !important;
	}
}

@media screen and (max-width: 768px) {
	.ant-layout-sider-zero-width-trigger-left {
		top: 72px !important;
	}
}

.timepicker .ant-picker-footer {
	display: none;
}

/* .reports-page-dropdown .ant-dropdown-menu-item {
	padding: 10px 30px;
	color: #ccc;
	position: relative;
} */

.ant-tooltip-inner {
	border-radius: 5px;
	background: #0f4364;
}

.menuPrimary .ant-dropdown-menu {
	background-color: #4bb7ba !important;
}

.creditTable th {
	font-weight: bold !important;
}

.userFilter .ant-select-selection-item-content {
	max-width: 50px;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
	background: #f3f4f6 !important;
}

.dot-marker {
	padding: 3px;
	border-radius: 3px;
	background-color: #374151;
	height: 3px;
	width: 3px;
	position: absolute;
}

/* Start wrap text antd select dropdown options*/
.ant-select-item-option-content {
	white-space: break-spaces;
	word-break: break-word;
}
.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
	height: auto;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
	white-space: normal;
	word-break: break-all;
}
/* End wrap text antd select dropdown options*/
