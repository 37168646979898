.rdw-storybook-toolbar-absolute {
	position: absolute;
	bottom: 0;
	z-index: 1;
	height: 50px;
	background: white;
	box-shadow: 0px 4.88136px 9.76271px rgba(12, 4, 66, 0.11);
	width: 100%;
}

.rdw-storybook-wrapper-margined {
	/* min-height: 200px; */
	margin-top: 10px;
}

.rdw-storybook-wrapper-margined * {
	font-family: 'Inter-Regular' !important;
	font-size: 16px;
}

.rdw-storybook-editor * {
	font-family: 'Inter-Regular' !important;
	font-size: 16px;
}

.rdw-storybook-editor {
	overflow-y: scroll;
	display: block;
	z-index: 0;
  position: relative;
}

.rdw-storybook-toolbar-absolute{
	height: unset !important;
}

.rdw-option-wrapper {
	border: none;
	padding: 15px 10px;
}

.rdw-option-active {
	box-shadow: none;
	background: #dee4e5;
}

.rdw-inline-wrapper,
.rdw-text-align-wrapper,
.rdw-list-wrapper {
	border-right: 0.5px solid #f0f0f0;
}
.rdw-option-wrapper:hover {
	box-shadow: none;
	background-color: #f0f0f0;
}
.attachment {
	margin-top: 2px;
	position: relative;
}

.attachment input {
	position: absolute;
	cursor: pointer;
	left: 0px;
	height: 100%;
	width: 100%;
	opacity: 0;
	background: #00f;
	z-index: 999;
}

.public-DraftStyleDefault-block {
	margin: 2px;
}

.rdw-editor-main {
	word-break: break-word;
	scrollbar-width: none; /* Firefox */
	-ms-overflow-style: none;
}

.rdw-editor-main::-webkit-scrollbar {
	width: 0px;
	background: transparent; /* Chrome/Safari/Webkit */
}

.rdw-colorpicker-option {
	padding: 0px !important;
}

.rdw-image-modal {
	top: inherit;
	bottom: 0;
	z-index: 12;
}

.rdw-colorpicker-modal {
	bottom: 0;
	top: unset;
}
@media only screen and (max-width: 768px) and (min-width: 320px) {
	.rdw-storybook-toolbar-absolute {
		bottom: 0px;
	}
	.rdw-image-modal {
		bottom: 71px;
		left: -65px;
		z-index: 12;
	}
}
