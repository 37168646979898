.ReactModal__Content{
    -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.ReactModal__Content::-webkit-scrollbar {
    display: none;
  }

  .ReactModal__Content .ReactModal__Content--after-open{
    padding: 0px !important;
  }
  .ReactModal__Content{
    overflow: auto !important;
  }