/*Ok button*/
.ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
	font-family: 'Inter-Regular' !important;
	font-size: 12px !important;
	background: #1582a4 !important;
	color: #fff !important;
	border-radius: 4px !important;
}

.ant-btn-primary {
	background: #1582a4 !important;
	color: #fff !important;
	border-color: #1582a4 !important;
	border-radius: 4px !important;
}

.ant-picker {
	height: 46px !important;
	border-radius: 5px !important;
	border: 1px solid rgb(222, 228, 229);
	border-right-width: 1px;
	width: 100%;
	margin-bottom: 30px;
	background-color: rgb(252, 252, 252);
}

/*Calander*/
.ant-picker-panel-container {
	width: 100% !important;
	font-family: 'Inter-Medium' !important;
	font-size: 12px !important;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09) !important;
	border: none !important;
	color: #0c0442 !important;
	border-radius: 8px !important;
}

/*Content*/
.ant-picker-content th {
	color: #0c0442 !important;
	font-family: 'Inter-Regular' !important;
	font-size: 14px !important;
	font-weight: 400 !important;
	margin: 0.5rem !important;
}

.ant-picker-cell-inner {
	font-size: 12px !important;
	/* color: #0c0442; */
	font-family: 'Inter-Regular' !important;
	font-weight: 400 !important;
	margin: 0.5rem !important;
}

.ant-picker-cell-today {
	border-radius: 50% !important;
	border-color: #1582a4 !important;
}

/*Selected Range Colors*/

.ant-picker-cell-range-hover::before {
	background: #1582a4 !important;
	color: #fff !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end)
	.ant-picker-cell-inner {
	border-radius: 25% !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start)
	.ant-picker-cell-inner {
	border-radius: 25% !important;
}

.ant-picker-cell-range-hover .ant-picker-cell-inner {
	color: #fff !important;
}

.ant-picker-cell-range-hover-start::before {
	background: #1582a4 !important;
	color: #fff !important;
}

.ant-picker-cell-range-hover-end::before {
	background: #1582a4 !important;
	color: #fff !important;
}

.ant-picker-cell-range-hover-start .ant-picker-cell-inner {
	background: #1582a4 !important;
	color: #fff !important;
}

.ant-picker-cell-range-start .ant-picker-cell-inner {
	background: #1582a4 !important;
	color: #fff !important;
}

.ant-picker-cell-range-hover-end .ant-picker-cell-inner {
	background: #1582a4 !important;
	color: #fff !important;
}

.ant-picker-cell-in-range::before {
	background: #1582a4 !important;
	color: #fff !important;
}

.ant-picker-cell-in-range .ant-picker-cell-inner {
	color: #fff !important;
}

.ant-picker-cell-in-view .ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
	background: #1582a4 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
	background: #1582a4 !important;
}

/* .ant-picker-header-super-next-btn,
.ant-picker-header-super-prev-btn {
	display: none !important;
} */

/*calendar header*/
.ant-picker-header-view,
.ant-picker-header-view {
	font-family: 'Inter-SemiBold';
	font-size: 14px !important;
	color: #0c0442 !important;
}

/* Time Panel*/

.ant-picker-time-panel-column
	> li.ant-picker-time-panel-cell
	.ant-picker-time-panel-cell-inner {
	font-size: 12px !important;
	color: #0c0442 !important;
	font-family: 'Inter-Regular' !important;
	font-weight: 400 !important;
}

.mcp-date-picker-style {
	height: 46px !important;
	border-radius: 5px !important;
	font-family: 'Inter-Regular' !important;
}
.mcp-date-picker-style.ant-picker-focused {
	outline-color: 'secondary.50';
	outline-style: 'auto';
}
.mcp-date-picker-style input::placeholder {
	font-size: 16px !important;
}