.phone-input {
	width: 100% !important;
	font-family: Inter-Medium !important;
	border-radius: 4px !important;
	border-width: 1px !important;
	font-size: 1.25rem !important;
	box-sizing: border-box;
	padding-top: 1.2rem !important;
	padding-left: 50px !important;
	padding-bottom: 1.2rem !important;
	border: 1px solid !important;
	outline: none;
	color: #0c0442 !important;
	height: 44px !important;
	border-radius: 6px !important;
	background-color: #fcfcfc !important;
	position: relative;
	border-color: #9ca3af !important;
}
.error {
	border-color: #9b3634 !important;
	background-color: #fff1f1 !important;
}

.react-tel-input .flag-dropdown {
	border-color: #9ca3af;
}

.error .flag-dropdown {
	border-color: #9b3634 !important;
	background-color: #fff1f1 !important;
}
.flag-dropdown {
	width: 48px !important;
}
.react-tel-input .form-control {
	font-size: 14px !important;
}

input.form-control.phone-input::placeholder {
	font-family: 'Inter-Regular' !important;
	font-size: 14px !important;
	color: grey.150 !important;
}
.phone-number {
	z-index: 20;
}
