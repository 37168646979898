/*Ok button*/
.ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
	font-family: 'Inter-Regular' !important;
	font-size: 12px !important;
	background: #1582a4 !important;
	color: #fff !important;
	border-radius: 4px !important;
}

.ant-btn-primary {
	background: #1582a4 !important;
	color: #fff !important;
	border-color: #1582a4 !important;
	border-radius: 4px !important;
}

/*Calander*/
.ant-picker-panel-container {
	width: 100% !important;
	font-family: 'Inter-Medium' !important;
	font-size: 12px !important;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09) !important;
	border: none !important;
	color: #0c0442 !important;
	border-radius: 8px !important;
}

.rmdp-wrapper {
	width: max-content;
	box-shadow: 0 0 !important;
	text-align: center;
	background-color: white;
	border-radius: 5px;
}

.rmdp-day,
.rmdp-week-day {
	margin: 5px 7px;
}

.rmdp-day span {
	top: 0 !important;
	bottom: 0 !important;
	left: 0 !important;
	right: 0 !important;
}

.rmdp-week-day {
	color: black !important;
	font-weight: bold !important;
}

.rmdp-header-values {
	font-size: 1.2em;
	font-weight: bold;
}

.rmdp-day.rmdp-today span {
	/* background-color: white !important; */
	color: black !important;
}

.rmdp-arrow {
	border: solid #1582a4 !important;
	border-width: 0 2px 2px 0 !important;
	width: 10px !important;
	height: 10px !important;
}

.rmdp-left {
	left: 70px !important;
}

.rmdp-right {
	right: 70px !important;
}

.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
	background-color: #1582a4 !important;
	color: white !important;
}

.rmdp-day.rmdp-deactive {
	background-color: #fafafa !important;
	color: #8c8b95 !important;
	border-radius: 15px;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
	background-color: #1582a4 !important;
	box-shadow: 0 0 !important;
	padding: 5px;
	color: white !important;
}

.rdmp-custom {
	border-radius: 15px;
	border: 1px #0c8af8 solid;
	padding: 4px 12px;
	background-color: white;
	height: 22px;
}

/*Content*/
.ant-picker-content th {
	color: #0c0442 !important;
	font-family: 'Inter-Regular' !important;
	font-size: 14px !important;
	font-weight: 400 !important;
	margin: 0.5rem !important;
}

.ant-picker-cell-inner {
	font-size: 12px !important;
	/* color: #0c0442; */
	font-family: 'Inter-Regular' !important;
	font-weight: 400 !important;
	margin: 0.5rem !important;
}

.ant-picker-cell-today {
	border-radius: 50% !important;
	border-color: #1582a4 !important;
}

/*Selected Range Colors*/

.ant-picker-cell-range-hover::before {
	background: #1582a4 !important;
	color: #fff !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end)
	.ant-picker-cell-inner {
	border-radius: 25% !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start)
	.ant-picker-cell-inner {
	border-radius: 25% !important;
}

.ant-picker-cell-range-hover .ant-picker-cell-inner {
	color: #fff !important;
}

.ant-picker-cell-range-hover-start::before {
	background: #1582a4 !important;
	color: #fff !important;
}

.ant-picker-cell-range-hover-end::before {
	background: #1582a4 !important;
	color: #fff !important;
}

.ant-picker-cell-range-hover-start .ant-picker-cell-inner {
	background: #1582a4 !important;
	color: #fff !important;
}

.ant-picker-cell-range-start .ant-picker-cell-inner {
	background: #1582a4 !important;
	color: #fff !important;
}

.ant-picker-cell-range-hover-end .ant-picker-cell-inner {
	background: #1582a4 !important;
	color: #fff !important;
}

.ant-picker-cell-in-range::before {
	background: #1582a4 !important;
	color: #fff !important;
}

.ant-picker-cell-in-range .ant-picker-cell-inner {
	color: #fff !important;
}

.ant-picker-cell-in-view .ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
	background: #1582a4 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
	background: #1582a4 !important;
}

/* .ant-picker-header-super-next-btn,
.ant-picker-header-super-prev-btn {
	display: none !important;
} */

/*calendar header*/
.ant-picker-header-view,
.ant-picker-header-view {
	font-family: 'Inter-SemiBold';
	font-size: 14px !important;
	color: #0c0442 !important;
}

/* Time Panel*/

.ant-picker-time-panel-column
	> li.ant-picker-time-panel-cell
	.ant-picker-time-panel-cell-inner {
	font-size: 12px !important;
	color: #0c0442 !important;
	font-family: 'Inter-Regular' !important;
	font-weight: 400 !important;
}

.mcp-date-picker-style {
	height: 46px !important;
	border-radius: 5px !important;
	font-family: 'Inter-Regular' !important;
}

.mcp-date-picker-style.ant-picker-focused {
	outline-color: 'secondary.50' !important;
	outline-style: 'auto' !important;
}

.mcp-date-picker-style input::placeholder {
	font-size: 16px !important;
	font-size: 16px !important;
	color: #b3b3b3;
}

 .ant-picker-input>input {
	box-shadow: none!important;
 }

/* to hide second calendar */
.ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
	visibility: visible !important;
}

.ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
	visibility: visible !important;
}

.ant-picker-panels > *:last-child {
	display: none !important;
}

.ant-picker-panel-container,
.ant-picker-footer {
	width: 280px !important;
}

.ant-picker-footer-extra > div {
	flex-wrap: wrap !important;
}
