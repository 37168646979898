/*@import '~antd/dist/antd.css';*/

@import 'atomic.css';
@import 'antd-override.css';
@import 'outseta-override.css';
@import 'geosuggest.css';

.scroll-hide::-webkit-scrollbar {
	width: 0px;
	background: transparent;
}

.scroll-hide {
	scrollbar-width: none; /* Firefox */
	-ms-overflow-style: none;
}

.ant-select-item-option {
	background-color: #fcfcfc !important;
}

.ant-select-item-option-selected {
	background-color: #f5f5f5 !important;
}

.ant-select-item-option-content {
	word-wrap: break-word !important;
	text-overflow: unset !important;
	word-break: unset !important;
}

div:focus-visible {
	outline: none;
}

.hideOkBtn .ant-picker-footer {
	display: none !important;
}

.card-element {
	border: 1px solid #dee4e5;
	padding: 1.2rem;
	border-radius: 5px;
	margin-bottom: 2rem;
}

.card-error {
	color: #fff1f1;
	font-size: 1.4rem;
}

/* Menu Link */
.menulink {
	color: #fff !important;
	padding: 12.425px 16px;
	font-size: 16px;
	font-family: 'Inter-Regular';
	line-height: 145.9%;
	margin-bottom: 8px;
}
.menulink.active {
	background: #4bb7ba;
	border-radius: 5px;
}

.box-shadow {
	border: 1px solid #e5e7eb;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
	border-radius: 5px;
}

/* Navbar tabs */
.custom-nav-tabs .ant-tabs-nav {
	margin-bottom: 0;
}
.custom-nav-tabs .ant-tabs-nav .ant-tabs-tab {
	font-weight: 600;
	font-size: 16px;
	line-height: 145.9%;
	color: #374151;
}
.custom-nav-tabs .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {
	color: #4bb7ba;
}
.custom-nav-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: #4bb7ba;
}
.custom-nav-tabs .ant-tabs-tab {
	padding-top: 23px;
	padding-bottom: 16px;
}

#search-bar::placeholder,
#search-bar::-ms-input-placeholder,
#search-bar:-ms-input-placeholder {
	color: red;
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
	border-color: #4bb7ba !important;
	border-width: 2px !important;
	box-shadow: none !important;
}

.custom-task-type-button .ant-radio-button-wrapper {
	height: 72px;
	border: 1px solid #9ca3af;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
	border-radius: 5px;
}

.custom-task-type-button .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
	outline-color: #4bb7ba;
	outline-width: 3px;
	outline-style: auto;
}
.custom-task-type-button .ant-radio-button-wrapper:not(:first-child):before {
	display: none;
}

.large-field .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
	border: 1px solid #9ca3af;
	box-sizing: border-box;
	min-height: 41px;
	padding-top: 10px !important;
	padding-bottom: 10px !important;
	padding-left: 10px !important;
}

.large-field input {
	border: 1px solid #9ca3af;
	box-sizing: border-box;
	border-radius: 5px;
	min-height: 41px;
	width: 100%;
	padding-left: 10px !important;
}

.search-location-input input {
	border: 1px solid #9ca3af;
	box-sizing: border-box;
	border-radius: 5px;
	min-height: 41px;
	width: 100%;
	padding-left: 10px !important;
}

.large-field input.bg {
	background: #f7f7f7;
}

.vieweditorcontent {
	word-break: break-word;
}

.vieweditorcontent span {
	font-family: 'Inter-Regular' !important;
	font-size: 16px !important;
}

.vieweditorcontent img {
	width: 100%;
}

.state-Checkout .o--PlanOptionsSelector--planOptions,
.state-Checkout h1 {
	display: none;
}

.o--ChargeSummary--chargeSummary {
	display: none;
}
body {
	scroll-behavior: smooth;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
	height: auto;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
	white-space: normal;
	word-break: break-word;
}

@media (max-width: 700px) {
	.custom-nav-tabs {
		width: 82vw;
	}
	body {
		scroll-behavior: smooth;
	}

	.prevent-select {
		-webkit-user-select: none; /* Safari */
		-ms-user-select: none; /* IE 10 and IE 11 */
		user-select: none; /* Standard syntax */
	}

	#zmmtg-root {
		display: none;
		position: absolute;
		z-index: 10;
	}
	.custom-nav-tabs .ant-tabs-nav-wrap {
		overflow-x: scroll !important;
	}
}

@media (max-width: 992px) {
	.custom-nav-tabs {
		margin-left: 20px;
	}
	.custom-nav-tabs .ant-tabs-nav .ant-tabs-tab:hover {
		color: #374151 !important;
	}
}
/* .StripeElement {
	font-family: 'Inter-Regular!important';
} */
