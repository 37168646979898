.mcp-table table {
	min-width: auto;
}
.ant-table .ant-checkbox-indeterminate .ant-checkbox-inner:after {
	background-color: #4bb7ba;
}
.ant-table-selection-col {
	width: 60px;
}
.ant-table-thead > tr > th {
	padding-top: 9px;
	padding-bottom: 9px;
}
.ant-table-tbody > tr > td {
	padding-top: 15.3px;
	padding-bottom: 15.3px;
}
.ant-table .ant-checkbox-checked .ant-checkbox-inner {
	background-color: #4bb7ba;
	border-color: #4bb7ba;
}
.ant-table-content .ant-checkbox-inner {
	width: 20px;
	height: 20px;
}

/* Pagination */
.mcp-pagination {
	margin-top: 16px !important;
	margin-bottom: 16px !important;
	float: right;
	width: 100%;
	padding-left: 16px !important;
	padding-right: 24px !important;
}

.mcp-pagination .ant-select-arrow {
	color: #1582a4 !important;
}

.mcp-pagination .ant-pagination-item-active {
	border-color: #1582a4 !important;
	color: #1582a4 !important;
}

.mcp-pagination .ant-pagination-prev,
.mcp-pagination .ant-pagination-item {
	margin-right: 0;
}
.mcp-pagination .ant-pagination-prev button {
	border-radius: 6px 0px 0px 6px;
}
.mcp-pagination .ant-pagination-next button {
	border-radius: 0px 6px 6px 0px;
}

.mcp-pagination .ant-pagination-prev button,
.mcp-pagination .ant-pagination-next button {
	width: 38px;
	height: 38px;
}

.mcp-pagination .ant-pagination-item-active a {
	background: #cae3ff;
	/* border: 1px solid #4BB7BA; */
	color: #4bb7ba;
}

.mcp-pagination .ant-pagination-item {
	font-size: 14px;
	line-height: 20px;
	font-family: 'Inter-Medium';
	height: 38px;
}

.mcp-pagination .ant-pagination-item a {
	padding: 8px 15px;
}

.mcp-pagination .ant-pagination-options-size-changer {
	display: inline-flex !important;
	align-items: center;
}

.mcp-pagination .ant-pagination-options-size-changer {
	height: 38px;
}

.mcp-pagination .ant-pagination-options-size-changer::before {
	content: 'VIEW';
	margin-right: 10px;
	font-family: Inter-medium;
	color: #0c0442;
	font-size: 12px;
	font-weight: 500;
}

.mcp-pagination .ant-select-selection-item {
	line-height: 38px !important;
	color: #0c0442;
	width: 20px;
	margin-right: 20px;
}

.mcp-pagination .ant-select-selector {
	/* border-color: #1582a4 !important; */
	color: #1582a4 !important;
	border-color: #d9d9d9 !important;
	border-radius: 6px;
	height: 38px;
	padding: 2px 10px;
}

.mcp-pagination .ant-select:not(.ant-select-customize-input) .ant-select-selector {
	height: 38px;
	border-radius: 6px;
	width: 135px;
}

.mcp-pagination .ant-select-arrow .anticon > svg {
	fill: #d9d9d9;
}

.mcp-pagination .ant-pagination-total-text {
	margin-right: auto;
}
@media only screen and (max-width: 768px) and (min-width: 320px) {
	.mcp-pagination {
		margin: 16px 0 16px 0 !important;
		padding-left: 0 !important;
		padding-right: 0 !important;
		justify-content: flex-start;
	}
	.mcp-pagination .ant-pagination-total-text {
		width: 100%;
	}
}
